import React from "react";

const About = () => {
  return (
    <>
      <div className="policy-container">
        <h1 className="policy-title">Welcome to Playdates App!</h1>
        <h1 className="policy-heading">Our Mission</h1>
        <div className="policy-text">
          At Playdates App, our mission is to help adults connect and schedule impromptu plans with strangers. We believe in fostering spontaneous connections and facilitating genuine experiences.
        </div>
        <h1 className="policy-heading">Who We Are</h1>
        <div className="policy-text">
          Playdates App was something I’ve always wanted to exist, so I set out to make a platform where individuals can come together, explore new activities, and form friendships.
        </div>
        <h1 className="policy-heading">What We Offer</h1>
        <div className="policy-text">
          I've crafted Playdates App to offer features designed to make it easy for users to connect and organize impromptu meetups:
          <ul>
            <li>Instant Connections</li>
            <li>Real-Time Availability</li>
            <li>Safety and Security</li>
            <li>Explore New Activities</li>
            <li>Community Engagement</li>
          </ul>
        </div>
        <h1 className="policy-heading">Get Started</h1>
        <div className="policy-text">
          Ready to embrace spontaneity and connect with new friends? Download Playdates App today and start planning your next impromptu adventure!
        </div>
        <h1 className="policy-heading">Contact Me</h1>
        <div className="policy-text">
          Have questions or feedback? Reach out to me at <a href="mailto: support@playdatesapp.us">support@playdatesapp.us</a>.
          <br />Thank you for joining me on this journey!
        </div>
      </div>
    </>
  );
};

export default About;
