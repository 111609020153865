import React from "react";

const TermsOfService = () => {
  return (
    <>
      <div class="policy-container">
        <h1 className="policy-title">TERMS AND CONDITIONS
        </h1>
        <p class="Policy-text">
          Welcome to Playdates App! These terms and conditions outline the rules and regulations for the use of our mobile application.
        </p>
        <p class="Policy-text">
          By accessing Playdates App, we assume you accept these terms and conditions. Do not continue to use Playdates App if you do not agree to all of the terms and conditions stated on this page.
        </p>

        <div>
          <h2 class="policy-heading">TABLE OF CONTENTS</h2>

          <p class="Policy-text">
            <ol>
              <li>USE OF THE APP
              </li>
              <li>USER CONTENT</li>
              <li>PRIVACY
              </li>
              <li>PROHIBITED ACTIONS</li>
              <li>INTELLECTUAL PROPERTY
              </li>
              <li>TERMINATION</li>
              <li>CHANGE TO TERMS</li>
              <li>CONTACT US</li>
            </ol>
          </p>
        </div>

        <div>
          <h2 class="policy-heading">1. USE OF THE APP
          </h2>
          <p class="Policy-text">
            1.1. You must be at least 18 years old to use Playdates App. If you are under 18, you must have permission from a parent or legal guardian to use the app.
          </p>
          <p class="Policy-text">
            1.2. You agree to use Playdates App in accordance with all applicable laws and regulations, including but not limited to laws related to online conduct, data privacy, and intellectual property.
          </p>
          <p class="Policy-text">
            1.3. You acknowledge that Playdates App is intended for personal, non-commercial use only. You agree not to use the app for any commercial purposes without prior written consent from Playdates App.
          </p>
        </div>

        <div>
          <h2 class="policy-heading">2. USER CONTENT</h2>
          <p class="Policy-text">
            2.1. You are solely responsible for any content, including but not limited to text, images, and videos, that you post or upload on Playdates App. You retain ownership of any content you create, but by posting or uploading content on the app, you grant Playdates App a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content in connection with the operation of Playdates App.
          </p>

          <p class="Policy-text">2.2. You warrant that any content you post does not violate the rights of others, including intellectual property rights, privacy rights, and publicity rights. You agree not to post or upload any content that is unlawful, defamatory, obscene, offensive, or otherwise objectionable.
          </p>
        </div>

        <div>
          <h2 class="policy-heading">3. PRIVACY</h2>
          <p class="Policy-text">
            3.1. We respect your privacy and are committed to protecting your personal information. Our Privacy Policy explains how we collect, use, and safeguard your data. By using Playdates App, you consent to the collection and use of your information as described in our Privacy Policy.
          </p>
        </div>

        <div>
          <h2 class="policy-heading">4. PROHIBITED ACTIONS
          </h2>

          <p class="Policy-text">
            4.1. You agree not to engage in any of the following prohibited activities while using Playdates App:

            <ul>
              <li>Transmitting spam or unsolicited messages.</li>
              <li>Attempting to access, hack, or disrupt Playdates App's servers or networks.
              </li>
              <li>Violating any applicable laws or regulations.</li>
              <li>Impersonating another person or entity.
              </li>
              <li>Harassing, intimidating, or stalking other users.
              </li>
              <li>Uploading viruses, malware, or other malicious code.
              </li>
              <li>Posting false, misleading, or deceptive information.
              </li>
              <li>Collecting or harvesting any information from Playdates App without our express written consent.
              </li>
            </ul>
          </p>
        </div>

        <div>
          <h2 class="policy-heading">5. INTELLECTUAL PROPERTY
          </h2>
          <p class="Policy-text">
            5.1. All content and materials available on Playdates App, including but not limited to text, graphics, logos, images, and software, are the property of Playdates App or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, copy, reproduce, modify, adapt, distribute, or create derivative works of any content from Playdates App without prior written consent from Playdates App.
          </p>
        </div>

        <div>
          <h2 class="policy-heading">6. TERMINATION
          </h2>
          <p class="Policy-text">
            6.1. We reserve the right to suspend or terminate your access to Playdates App at any time and for any reason, without prior notice. In the event of termination, you will no longer be able to access your account or use Playdates App. Any provisions of these terms and conditions that by their nature should survive termination shall survive termination, including but not limited to provisions related to intellectual property, disclaimer of warranties, limitations of liability, and indemnification.
          </p>
        </div>

        <div>
          <h2 class="policy-heading">7. CHANGES TO TERMS

          </h2>
          <p class="Policy-text">
            7.1. Playdates App reserves the right to revise these terms and conditions at any time without prior notice. By continuing to use Playdates App after any revisions become effective, you agree to be bound by the updated terms and conditions. It is your responsibility to review these terms and conditions periodically for changes.
          </p>
        </div>

        <div>
          <h2 class="policy-heading">8. CONTACT US
          </h2>
          <p class="Policy-text">
            8.1. If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto: support@playdatesapp.us">support@playdatesapp.us</a>
          </p>

          <p class="Policy-text">
            By using Playdates App, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.
          </p>
        </div>

      </div>
    </>
  );
};

export default TermsOfService;
